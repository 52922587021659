@import "../../styles/vars";

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;

    .inner_container {
        width: 100%;
        height: fit-content;

        .title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            span {
                cursor: pointer;
            }
        }

        .redelegate_msg {
            max-width: 75%;
            margin-top: 15px;
        }

        .redelegation_choices {
            margin-top: 17px;

            .choices {
                display: flex;
                flex-direction: column;
            }
        }

        .wallets {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 50px;

            .wallet_name {
                font-size: 18px;
                line-height: 23px;
                font-weight: 700;
            }
        }

        .stake_unstake_nav {
            width: fit-content;
            margin-top: 42px;
            padding: 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0;
            border: 1px solid $GREY_4;
            border-radius: 10px;
        
            .choice {
                font-weight: 500;
                font-size: 14px;
                padding: 8px 30px;
                text-transform: uppercase;

                &:first-of-type {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &:last-of-type {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }

        .stake_info {
            margin-top: 30px;
            margin-bottom: 80px;
            width: 100%;
            height: fit-content;

            .amount {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
            }

            .number {
                margin-top: 10px;
                max-width: 338px;
                padding: 12px 14px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border: 2px solid #d1d1d1;
                border-radius: $RADIUS_S;
                
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 38px;

                input, .balance {
                    width: 100%;
                    outline: none;
                }

                .max_btn {
                    font-size: 12px;
                    line-height: 12px;
                    padding: 12px 18px;
                    background-color: black;
                    color: white;
                    box-shadow: 2.97414px 2.37931px 11.8966px 7.13793px rgba(0, 0, 0, 0.09);
                    border-radius: 5px;
                    cursor: pointer;
                    user-select: none;
                }
            }

            .total_balance {
                margin-top: 10px;
            }
        }
    }    
}