@import "../../styles/vars";

#profile_container {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin: 60px 0;

    @media all and (max-width: $MOBILE_BREAKPOINT) {
        margin: 30px 0;
    }
}