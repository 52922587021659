@import "../../styles/vars";

#nav {
    height: 75px;
    width: 100vw;
    background-color: $ORANGE_5;
    display: grid;
    place-items: center;
    padding: 0 50px;
    z-index: 1000;
    position: fixed;

    @media all and (max-width: $TABLET_PX) {
        padding: 0;
    }

    .container {
        width: 100%;
        height: 100%;
        max-width: $DESKTOP_CONTAINER_PX;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media all and (max-width: $TABLET_PX) {
            padding: 0 32px;
        }

        @media all and (max-width: 448px) {
            padding: 0 16px;
        }

        .left {
            display: flex;
            flex-direction: row;
            gap: 54px;

            .logo_title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 11px;
                user-select: none;
                cursor: pointer;
        
                .logo {
                    border-radius: 50%;
                    height: 42px;
                    width: auto;
                }
        
                .title {
                    color: $WHITE;
                    text-transform: uppercase;

                    @media all and (max-width: 440px) {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        
            ul {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 40px;
                list-style-type: none;

                @media all and (max-width: $TABLET_PX) {
                    display: none;
                }
    
                li {
                    cursor: pointer;
                    user-select: none;
                    display: inline-block;
                    padding: 2.5px 0;
                    position: relative;
                    color: $WHITE;

                    &:after {    
                        background: none repeat scroll 0 0 transparent;
                        bottom: 0;
                        content: "";
                        display: block;
                        height: 2px;
                        left: 50%;
                        position: absolute;
                        background: $WHITE;
                        transition: width 0.3s ease 0s, left 0.3s ease 0s;
                        width: 0;
                    }

                    &:hover:after { 
                        width: 100%; 
                        left: 0; 
                    }

                    a {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .right {
            display: flex;
            flex-direction: row; 
            align-items: center;
            gap: 15px;
        }
    }
}

.mobile_links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 75px;
    right: 0;
    width: 100%;
    background: $ORANGE_5;
    color: $WHITE;
    padding: 0 15px 10px 15px;

    li {
        list-style: none;
        width: 100%;
        padding: 20px 0;
        border-top: 1px solid white;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}