@import "../../../styles/vars";

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    .close_button {
        margin-top: 3px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .inner_container {
        width: 100%;
        height: fit-content;

        .title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .wallets {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 50px;
        }

        .accept_terms {
            margin-top: 40px;

            .conditions {
                span {
                    a {
                        font-weight: 700;
                        cursor: pointer;
                    }
                }
            }
        }
        
        .accept_button {
            width: 100%;
            margin-top: 50px;

            button {
                background: $GREY_4;
                color: $WHITE;
                padding: 18px 24px;
                box-shadow: $SHADOW_BUTTONS;
                border-radius: $RADIUS_S;
                cursor: default;
            }
        }
    }    
}

.menu {
    border-radius: $RADIUS_L !important;
    box-shadow: $SHADOW_DOUBLE !important;

    .disconnect {
        padding: $SPACING_XS $SPACING_S;
        width: 100%;
        height: 100%;
        cursor: pointer;
        user-select: none;
    }
}