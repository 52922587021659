@import "../../styles/vars";

.profile_container {
    display: flex;
    flex-direction: column;
    gap: 21px;

    @media all and (max-width: $MOBILE_BREAKPOINT) {
        gap: 15px;
    }

    .title {
        color: $ORANGE_5;
    }

    .profile {
        padding: 60px 120px;
        width: 100%;
        height: fit-content;
        background: white;
        border: 1px solid $GREY_4;
        border-radius: $RADIUS_L;
        box-shadow: $SHADOW_MAIN;

        display: flex;
        flex-direction: column;

        @media all and (max-width: $DESKTOP_CONTAINER_PX) {
            padding: 30px 40px;
        }

        @media all and (max-width: $MOBILE_BREAKPOINT) {
            padding: 25px;
        }

        .user_icon {
            width: 60px;
            height: 60px;
        }

        .user_name_addr {
            margin-top: 25px;

            @media all and (max-width: $MOBILE_BREAKPOINT) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }
            .name {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media all and (max-width: $MOBILE_BREAKPOINT) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                }
            }
        }

        .info_molecules {
            margin-top: 40px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 35px;

            @media all and (max-width: $TABLET_PX) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media all and (max-width: $MOBILE_BREAKPOINT) {
                grid-template-columns: repeat(1, 1fr);
                row-gap: 25px;
            }

            .bufferer {
                @media all and (max-width: $TABLET_PX) {
                    display: none;
                }
            }
        }

        .bio_container {
            margin-top: 45px;

            p {
                margin-top: 7px;
            }
        }

        .create_profile {
            margin-top: 40px;
            width: 100%;
            height: 275px;
            background: $ORANGE_1_2;
            
            display: grid;
            place-items: center;
        }

        .see_address {
            width: fit-content;
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            gap: 5px;
            border-bottom: 1px solid $GREY_4;
            cursor: pointer;

            span {
                img {
                    margin-top: -1px;
                }
            }
        }
    }
}