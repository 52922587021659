@import "../../styles/vars";

#footer {
    position: sticky;
    top: 100vh;
    width: 100vw;
    height: 337px;
    background: $GREY_1;
    padding: 118px 50px;
    color: $WHITE;

    @media all and (max-width: $TABLET_PX) {
        padding: 34px 30px;
        height: 239px;
    }

    .container {
        left: 0;
        right: 0;
        margin: auto;
        max-width: 1065px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media all and (max-width: $TABLET_PX) {
            height: fit-content;
            flex-direction: column;
            justify-content: baseline;
        }

        .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;    

            @media all and (max-width: $TABLET_PX) {
                gap: 16px;
            }
        }

        .right {
            height: fit-content;
            display: flex;
            flex-direction: row;
            gap: 32px;

            @media all and (max-width: $TABLET_PX) {
                width: 100%;
                margin-top: 26px;
                padding: 12px 0;
                border-top: 1px solid $GREY_3;
            }

            .links {
                line-height: 28px;
            }
        }
    }
}