@import "../../../styles/vars";
@import "../../../styles/classes";

.black {
    padding: 18px 24px;
    width: fit-content;
    height: fit-content;
    box-shadow: $SHADOW_BUTTONS;
    border-radius: $RADIUS_S;
    cursor: pointer;
    user-select: none;
    transition-duration: 0.25s;
    background-color: $GREY_1;
    color: $WHITE;
}

.black_btn_round {
    font-family: "Gotham Light", sans-serif;
    font-size: 14px;
    line-height: 18px;
    background: $GREY_1;
    color: $WHITE;
    border-radius: 30px;
    padding: 8px 12px;
    user-select: none;
}

.connect {
    padding: 18px $SPACING_BASE;
    background: $GREY_1;
    color: $WHITE;
    border-radius: $RADIUS_XL;
    box-shadow: $SHADOW_BUTTONS;

    @media all and (max-width: 448px) {
        padding: 11.5px 18px;
        //cta_3
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.2px;
    }
}

.connected {
    user-select: none;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px;
    padding: 13px 16px;
    background: $GREY_1;
    color: $WHITE;
    border-radius: $RADIUS_XL;
    box-shadow: $SHADOW_BUTTONS;

    @media all and (max-width: 448px) {
        padding: 6.5px 8px;
        //cta_3
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.2px;
    }

    img {
        width: 22px;
        height: 22px;
    }
}

.linker {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    width: fit-content;
    padding: 3.5px 8px;
    color: black !important;
    background: #FFF5EB;
    border-radius: 30px;

    font-size: 12px;
    line-height: 12px;

    img {
        width: 16px;
        height: 16px;
    }
}