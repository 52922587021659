@import "./classes";

html,
body {
  margin: 0;
  height: 100%;
  overflow: scroll;
}

html {
  font-family: "Gotham",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  box-sizing: border-box;
  background-color: #f8fafc;
}

.loading_anim {
  box-shadow: 0 0 0 0 $ORANGE_1;
	transform: scale(1);
	animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 $ORANGE_1;
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px $ORANGE_1;
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 $ORANGE_1;
    }
  }
}

/* Modal Styling */
.wallet-wc-modal--dim {
  background: transparent !important;
}

.wallet-wc-modal--content {
  border-radius: 19px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  box-shadow: none !important;

  h1 {
    font-family: "Gotham", sans-serif !important;

    img {
      display: none;
    }
  };

  p {
    font-family: "Gotham", sans-serif !important;
  }
}

.chakra-modal__content {
  border-radius: 19px !important;
}

.chakra-modal__close-btn {
  outline: none;
}

.css-ddil6j {
  border: 1px solid $GREY_1 !important;
  background: transparent !important;
}
 
.css-ddil6j[data-focus] {
  box-shadow: none !important;
}

.css-7roig {
  background: $GREY_1 !important;
}

.css-ddil6j[aria-checked=true], 
.css-ddil6j[data-checked] {
  .css-7roig {
    background: $ACCENT_2 !important
  }
}

.css-cqwx4z {
  box-shadow: none !important;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8fafc;
    padding: 0 50px;

    @media all and (max-width: $TABLET_PX) {
        padding: 0;
    }
}

main {
  margin-top: 75px;
  width: 100%;
  max-width: $DESKTOP_CONTAINER_PX;
  background-color: #f8fafc;

  @media all and (max-width: $TABLET_PX) {
      padding: 0 32px;
  }

  @media all and (max-width: $MOBILE_BREAKPOINT) {
      padding: 0 16px;
  }
}

.terms, .privacy {
  p {
    padding-bottom: 30px;
  }
}