//WIDTH RATIOS
$DESKTOP_PX: 1440px;
$TABLET_PX: 768px;
$MOBILE_PX: 375px;

$MOBILE_BREAKPOINT: 450px;

$DESKTOP_CONTAINER_PX: 1081px;
$TABLET_CONTAINER_PX: 704px;
$MOBILE_CONTAINER_PX: 343px;

//SPACING UNITS
$SPACING_XXS: 4px;
$SPACING_XS: 8px;
$SPACING_S: 16px;
$SPACING_BASE: 24px;
$SPACING_MD: 32px;
$SPACING_L: 48px;
$SPACING_XL: 64px;
$SPACING_XXL: 96px;

//COLOR UNITS
$ORANGE_1: #FEA345;
$ORANGE_1_3: #FFFAF5;
$ORANGE_2: #FD8204;
$ORANGE_3: #FD6A00;
$ORANGE_4: #FC5D02;
$ORANGE_5: #FC3E02;

$ORANGE_1_2: #FFF5EB;
$ORANGE_5_2: #FEC5B3;

$ACCENT_1: #14CACA;
$ACCENT_2: #00ACAC;
$ACCENT_1_2: #B1E9EA;

$GREY_1: #1E1E1E;
$GREY_2: #383838;
$GREY_3: #B2B9C6;
$GREY_4: #DEE3EB;
$GREY_5: #E9ECF1;
$GREY_6: #EEF2F8;

$WHITE: #FFFFFF;

//SHADOW UNITS
$SHADOW_MAIN: 0px 5px 5px rgba(56, 56, 56, 0.05);
$SHADOW_DOUBLE: 0px 10px 10px rgba(56, 56, 56, 0.1);
$SHADOW_BUTTONS: 0px 3px 7px rgba(56, 56, 56, 0.2);

//RADIUS_UNITS
$RADIUS_S: 4px;
$RADIUS_M: 8px;
$RADIUS_L: 16px;

$RADIUS_XL: 30px;