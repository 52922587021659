@import "../../styles/vars";

.dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;

    .title {
        width: 100%;
    }

    .graphics {
        margin-top: 32px;
        width: 100%;
        height: 160px;
        background-color: aliceblue;
        display: grid;
        place-items: center;

        img {
            width: 40px;
            height: 40px;
        }

        .rotate {
            animation: rotation 2s infinite linear;

            @keyframes rotation {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(359deg);
                }
            }
        }
    }

    .hash_title {
        margin-top: 25px;
        width: 100%;
    }

    .hash {
        margin-top: 4px;
        width: 100%;
    }

    .finder {
        margin-top: 30px;
        width: fit-content;
        height: fit-content;
        border-bottom: 1px solid $GREY_5;
    }

    .go_back {
        margin-top: 25px;
        text-transform: uppercase;
        cursor: pointer;
    }
}